<script setup>
import { userAuthState } from '@/@core/auth'
import { can } from '@/@layouts/plugins/casl'

definePage({
  meta: {
    name: 'incident-audit',
  },  
})

const auth = userAuthState()
// const canAccessUnit = can('read', 'masters.unit')
</script>

<template>
  Hello From Audit
</template>
