<script setup>
// Forms
import IncidentDetailsForm from "@/views/incident/incident-panel/forms/IncidentDetails.vue";
import InvolvedPartiesForm from "@/views/incident/incident-panel/forms/InvolvedParties.vue";
import IncidentQuestionsForm from "@/views/incident/incident-panel/forms/IncidentQuestions.vue";
import ReviewForm from "@/views/incident/incident-panel/forms/Review.vue";

definePage({
  meta: {
    name: "incident-collection",
    subject: "all",
    navActiveLink: "incident-collection",
  },
});

const iconsSteps = [
  {
    title: "Incident Details",
  },
  {
    title: "Involved Parties",
  },
  {
    title: "Incident Questions",
  },
  {
    title: "Review & Submit",
  },
];

const refForm = ref();

const incidentDetailsForm = ref({});
const involvedPartiesForm = ref({});
const incidentQuestionsForm = ref({});

const isCurrentStepValid = ref(true);
const currentStep = ref(0);

const validateForm = () => {
  refForm.value?.validate().then(({ valid }) => {
    if (valid) {
      currentStep.value++;
      isCurrentStepValid.value = true;
      nextTick(() => {
        refForm.value?.resetValidation();
      });
    } else {
      isCurrentStepValid.value = false;
    }
  });
};

const updateCurrentStep = (value) => {
  currentStep.value = value;
};

const submit = () => {
  // console.log(incidentDetailsForm.value)
  // console.log(involvedPartiesForm.value)
  // console.log(incidentQuestionsForm.value)

  console.log({
    ...incidentDetailsForm.value,
    ...involvedPartiesForm.value,
    ...incidentQuestionsForm.value,
  });
};
</script>

<template>
  <!-- 👉 Stepper -->
  <div class="mb-6">
    <AppStepper
      v-model:current-step="currentStep"
      :items="iconsSteps"
      :is-active-step-valid="isCurrentStepValid"
    />
  </div>

  <!-- 👉 stepper content -->
  <VForm>
    <VWindow v-model="currentStep" class="disable-tab-transition">
      <VWindowItem class="bg-var-theme-white">
        <VForm ref="refForm" @submit.prevent="validateForm">
          <IncidentDetailsForm
            :data="incidentDetailsForm"
            :current-step="currentStep"
            @update:form-data="incidentDetailsForm = $event"
            @update:current-step="updateCurrentStep"
          />
        </VForm>
      </VWindowItem>

      <VWindowItem class="bg-var-theme-white">
        <VForm ref="refForm" @submit.prevent="validateForm">
          <InvolvedPartiesForm
            :data="involvedPartiesForm"
            :current-step="currentStep"
            @update:form-data="involvedPartiesForm = $event"
            @update:current-step="updateCurrentStep"
          />
        </VForm>
      </VWindowItem>

      <VWindowItem class="bg-var-theme-white">
        <VForm ref="refForm" @submit.prevent="validateForm">
          <IncidentQuestionsForm
            :data="incidentQuestionsForm"
            :current-step="currentStep"
            @update:form-data="incidentQuestionsForm = $event"
            @update:current-step="updateCurrentStep"
          />
        </VForm>
      </VWindowItem>

      <VWindowItem class="bg-var-theme-white">
        <VForm ref="refForm" @submit.prevent="validateForm">
          <ReviewForm
            :data="{
              ...incidentDetailsForm,
              ...involvedPartiesForm,
              ...incidentQuestionsForm,
            }"
            :current-step="currentStep"
            @update:submit="submit"
            @update:current-step="updateCurrentStep"
          />
        </VForm>
      </VWindowItem>
    </VWindow>
  </VForm>
</template>
