<script setup>
import { userAuthState } from "@/@core/auth";
import { can } from "@/@layouts/plugins/casl";

import IncidentTable from "@/views/incident/incident-panel/IncidentTable.vue";
import IncidentDrawer from "@/views/incident/incident-panel/IncidentDrawer.vue";

import DummyFilterOptions from '@/utils/dummy-data/filter'
import DummyViewData from '@/utils/dummy-data/view'

definePage({
  meta: {
    name: "incident-incident",
    subject: "all",
  },
});

const auth = userAuthState();
// const canAccessUnit = can('read', 'masters.unit')

const filters = ref({})
const filterOptions = ref(DummyFilterOptions)

const isViewDrawerVisible = ref(false);
const viewData = ref(DummyViewData);

const showIncident = (data) => {
  // viewData.value = data;
  isViewDrawerVisible.value = true;
};
</script>

<template>
  <section>
    <VRow class="match-height mb-6">
      <VCol cols="12" md="6" lg="6">
        <FilterComponent
          v-model:filters="filters"
          :filters-options="filterOptions"
        />
      </VCol>
      <VCol cols="12" md="6" lg="6">
        <VCard>
          <VCardItem>
            Coming Soon
          </VCardItem>
        </VCard>
      </VCol>
    </VRow>

    <IncidentTable
      ref="refTable"
      :filters="{}"
      @show="showIncident"
    />

    <IncidentDrawer
      v-model:isDrawerOpen="isViewDrawerVisible"
      :data="viewData"
    />

  </section>
</template>
