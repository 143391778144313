<script setup>
import CustomRadiosWithOutLabel from '@/@core/components/app-form-elements/CustomRadiosWithOutLabel.vue'
import { useFeedbackEntry } from '@/@core/stores/feedback-entry'
import { updateObjectAsSelect } from '@/@core/utils/helpers'
import { cookieRef } from '@/@layouts/stores/config'
import QuestionGroup from '@/components/question/QuestionGroup.vue'
import GoogleTranslateSelect from '@google-translate-select/vue3'
import { themeConfig } from '@themeConfig'
import { nextTick, ref } from 'vue'
import { VSkeletonLoader } from 'vuetify/lib/labs/components.mjs'

definePage({ 
  meta: { 
    layout: 'client', 
    public: true, 
    unauthenticatedOnly: true,
    title: 'Write Feedback',
  }, 
})


const router = useRouter()
const route = useRoute()
const google_map_link = ref(null)

// we need to take source type form type and episode number from the url
const { s: sourceType, f: formType, e: episodeNumber, l: locationNo, u: unitValue } = route.query
const episodeNoValue =ref(null)

const location = ref(null)
const unitId = ref(null)
const sourceTypeValue = ref(null)
const formTypeValue = ref(null)
const isFeedbackPositive = ref(false)
const contactForm = ref()
try{
  episodeNoValue.value = episodeNumber ? atob(episodeNumber) : null
  location.value = locationNo ? atob(locationNo) : null
  sourceTypeValue.value = sourceType ? atob(sourceType) : null
  formTypeValue.value = formType ? atob(formType) : null
  unitId.value = unitValue ? atob(unitValue) : 1
}
catch(error){
  console.error('Error parsing URL:', error)
  router.push({ name: 'invalid-url', params: { code: 404,
    message: 'Invalid URL. Please try again with a valid URL.',
  
  } })
}

const feedbackData = reactive({
  formType: null,
  name: '',
  email: '',
  location: '',
  phone: '',
  uhid: '',
  episode_no: '',
  consent: false,
  feedback: [],
  unit_id: null,
})


// if source type is not present in the url then throw error
if (!sourceTypeValue.value) {
  router.push({ name: 'invalid-url', params: { code: 404 } })
}else{
  await useApi(createUrl('public/feedback-sources', 
    { 
      query: { 
        search: {
          status: 1,
          id: sourceTypeValue,
        },
      },
    },
  ),
  {
    beforeFetch: async ({ url, options, cancel }) => {
      if (!sourceTypeValue.value ) {
        cancel()
      }
    },
  }).then(response => {
    if (response.data.value.length === 0) {
      router.push({ name: 'invalid-url', params: { code: 404 } })
    }
    feedbackData.sourceType= response.data?.value[0]?.id
  })
}

const unit = ref(null)

if(unitId.value){
  await useApi(createUrl('public/units', 
    { 
      query: { 
        search: {
          id: unitId.value??1,
        },
      },
    },
  )).then(response => {
    unit.value = response.data?.value[0]
    google_map_link.value = response.data?.value[0]?.google_map_link
    feedbackData.unit_id = unitId.value
  })
}

// if form type is present validate it
if (formTypeValue.value) {
  await useApi(createUrl('public/form-types', 
    { 
      query: { 
        search: {
          status: 1,
          id: formTypeValue,
        },
        unit_id: unitId.value??1,
      },
    },
  )).then(response => {
    if (response.data.value.length === 0) {
      router.push({ name: 'invalid-url', params: { code: 404 } })
    }
    feedbackData.formType = response.data?.value[0]?.id
  })
}

// if episode number is present validate it
if (episodeNoValue.value) {
  feedbackData.episode_id = episodeNoValue
}

const updateFeedbackTypeFormData = QuestionAnswer => {
  // find index of question in data
  const index = feedbackData?.feedback?.findIndex(item => item.question_id === QuestionAnswer.question_id)
  if (index > -1) {
    feedbackData.feedback[index] = QuestionAnswer
  } else {
    feedbackData.feedback.push(QuestionAnswer)
  }
}

const currentStep = ref(0)

const { isFetchingSourceTypes, data: feedbackTypeRadio } =await useListFetchingService('public/form-types', 
  { 
    query: { 
      select: ['id', 'name', 'color'],
      search: {
        status: 1,
      },
      unit_id: unitId.value??1,
    },
  },
)

const selectedFormType =computed(() => {  
  return feedbackData.formType
})

const selectedFormTypeName =computed(() => {
  return feedbackTypeRadio.value?.find(item => item.id === feedbackData?.formType)
})

const beforeFetch =async ({ url, options, cancel }) =>{
  if (!feedbackData?.formType) {
    cancel()
  }else{
  }

}

const { isFetching: fetchingQuestions,  execute: refetchQuestions, data: questions } = useApi(createUrl('public/feedback/questions', {
  query: {
    ep: episodeNoValue.value??null,
    search: {
      status: 1,
      form_type_id: selectedFormType,
    },
  },
}),
{ beforeFetch,

  onFetchError: ({ data, error, response }) => {
    if (error) {
      router.push({ name: 'invalid-url', params: { code: response?.status??404, title: error?.message } })
    }
  },
},
)

const questionsComputed = computed(() => {
  return questions.value
})


watch(selectedFormType, ()=>{
  if(selectedFormType.value){
    refetchQuestions
  }
}, { deep: true, immediate: true })

const feedbackForm =ref()

const checkCurrentStep = computed(() => {
  if (currentStep.value === 0) {
    return feedbackData.consent
  }
  if (currentStep.value === 1) {
    return feedbackData.formType 
  }
  if (currentStep.value === 2) {
    return feedbackData.name && feedbackData.phone
  }
  if (currentStep.value === 3) {
    return !fetchingQuestions.value
  }

  return true
})

const buttonLabel = computed(() => {
  return currentStep.value === 3 ? 'Submit Feedback' : 'Continue'
})


const submitBtnLoading = ref(false)

const nextStep = () => {
  switch (currentStep.value) {
  case 0:
    if (feedbackData.formType) {
      currentStep.value += 2

      if (feedbackData.episode_id) {
        currentStep.value++
      }
      break
    }
    currentStep.value++

    break

  case 2:
    if (feedbackData.episode_id) {

      currentStep.value += 2
      break
    }
    contactForm.value?.validate().then(({ valid: isValid }) => {
      if (isValid) {
        currentStep.value += 1
      }
    })
    break

  case 3:
    feedbackForm.value?.validate().then(({ valid: isValid }) => {
      if (isValid) {
        submitBtnLoading.value = true
        useFeedbackEntry().updateCurrentFeedback({
          customer_type: (feedbackData?.uhid||feedbackData?.episode_id ) ? 'PATIENT' : 'VISITOR',
          source_type: feedbackData.sourceType??'QRCODE',
          form_type: feedbackData.formType,
          location_name: feedbackData.location,
          customer: {
            name: feedbackData.name,
            email: feedbackData.email,
            phone: feedbackData.phone,
            uhid: feedbackData.uhid,
            episode_no: feedbackData.episode_no,
            customer_id: feedbackData?.customer_id,
            episode_id: feedbackData?.episode_id,
            location_id: location.value,
          },
          unit_id: feedbackData.unit_id?? cookieRef('selectedUnit'),
          feedback: feedbackData.feedback,
        }).then(() => {
          isFeedbackPositive.value = feedbackData.feedback.some(item => item.questionKey==='NPS' && item.response?.label > 8)
          nextTick(() => {
            useFeedbackEntry().submitCurrentFeedback(true).then(() => {
              currentStep.value++
              submitBtnLoading.value = false
            })
          })
        })
      }
    })
    break
  default:
    currentStep.value += 1
  }
}


watch(feedbackData, () => {
  if (feedbackData && Array.isArray(feedbackData?.feedback) && feedbackData?.feedback?.length > 0){
    useFeedbackEntry().updateCurrentFeedback({
      feedback: feedbackData.feedback?.filter(item => {
        if (item.response !== null && item.response !== '' && item.response !== undefined) {
          return item
        }
      }),
    })
  }
}, {
  deep: true,
  immediate: true,
})

const previousStep = () => {
  if (currentStep.value === 3 && episodeNoValue.value) {
    currentStep.value = currentStep.value - 2
    
    return
  }
  if (currentStep.value === 2 && formTypeValue.value) {
    currentStep.value = currentStep.value - 2
    
    return
  }
  currentStep.value = currentStep.value - 1
}

const googleTranslateSelect =ref()

const languages = ref([
  {
    code: 'en',
    name: 'English',
    cname: '英语',
    ename: 'English',
  },
  {
    code: 'hi',
    name: 'हिंदी',
    cname: '印地语',
    ename: 'Hindi',
  },
  
])
</script>

<template>
  <div class="feedback-page">
    <VContainer
      fluid
      class="feedback-container"
    >
      <div class="feedback-card">
        <VCard>
          <!-- class binding if current step is 4 than no padding -->
          <VCardText class="px-2">
            <VWindow
              v-model="currentStep"
              class="disable-tab-transition"
              :touch="false"
            >
              <VWindowItem>
                <VCol>
                  <GoogleTranslateSelect
                    ref="googleTranslateSelect"
                    default-language-code="en"
                    default-page-language-code="en"
                    :fetch-browser-language="false"
                    trigger="click"
                    class="w-100"
                    :languages="languages"
                  />
                </VCol>
                <!-- Welcome page ask  -->
                <VCardItem>
                  <div class="text-center text-primary text-h5 mb-5">
                    Welcome to {{ unit?.name ?? themeConfig.app.client.name }}
                  </div>
                  <div class=" text-body-1">
                    <p class="">
                      Dear Sir / Madam,
                      Thank you for choosing {{ unit?.name ?? themeConfig.app.client.name }} as your center of choice for treatment.

                      We would like to know your feedback on the services provided by us. Your feedback will help us to improve our services and serve you better.
                    </p>                 
                  </div>
                  <!-- consent and continue add in the bottom -->
                  <VCardActions>
                    <div class="d-flex align-start">
                      <VCheckbox
                        v-model="feedbackData.consent"
                        label="I agree to provide feedback"
                        color="primary"
                      />
                    </div>
                    <VSpacer />
                  </VCardActions>
                </VCardItem>
              </VWindowItem>
              <VWindowItem>
                <!-- Feedback form type -->
                <VCardItem>
                  <div class="text-center text-primary text-h5 mb-5">
                    Select The Service you want to provide feedback for
                  </div>
                    
                  <VRow>
                    <VCol cols="12">
                      <CustomRadiosWithOutLabel
                        v-if="!isFetchingSourceTypes"
                        v-model:selected-radio="feedbackData.formType"
                        :radio-content="updateObjectAsSelect(feedbackTypeRadio)"
                        :rules="[requiredValidator]"
                        colored
                      />
                    </VCol>
                  </VRow>
                </VCardItem>
              </VWindowItem>
              <VWindowItem>
                <VForm ref="contactForm">
                  <!-- Customer information -->
                  <div class="text-center text-primary text-h5 mb-5">
                    {{ $t('Customer') }} Information
                  </div>
                  <VCardItem>
                    <VRow>
                      <VCol
                        cols="12"
                        md="4"
                      >
                        <AppTextField
                          v-model="feedbackData.name"
                          label="Name"
                          placeholder="Enter Name"
                          required

                          :rules="[requiredValidator]"
                        />
                      </VCol>
              
                      <VCol
                        cols="12"
                        md="4"
                      >
                        <AppPhoneInput
                          v-model="feedbackData.phone"
                          label="Mobile Number"
                          placeholder="Enter Mobile Number"
                          :rules="[requiredValidator]"
                          required
                        />
                      </VCol>
              
                      <VCol
                        cols="12"
                        md="4"
                      >
                        <AppTextField
                          v-model="feedbackData.uhid"
                          label="UHID No"
                          placeholder="Enter UHID"
                        />
                      </VCol>
            
                      <VCol
                        cols="12"
                        md="4"
                      >
                        <AppTextField
                          v-model="feedbackData.email"
                          label="Email"
                          type="email"
                          placeholder="Enter Email"
                          :rules="[emailValidator]"
                        />
                      </VCol>
              
                      <VCol
                        v-if="!location"
                        cols="12"
                        md="4"
                      >
                        <AppTextField
                          v-model="feedbackData.location"
                          label="Location"
                          placeholder="Enter Location"
                        />
                      </VCol>
                    </VRow>
                  </VCardItem>
                </VForm>
              </VWindowItem>
              <VWindowItem>
                <VContainer fluid>
                  <div class="d-flex justify-center align-center">
                    <VAvatar
                      size="32"
                      color="primary"
                      variant="tonal"
                    >
                      <VIcon
                        size="18"
                        icon="tabler-chart-pie"
                      />
                    </VAvatar>
                    <h4 class="text-h5 ms-3 mb-0">
                      {{ selectedFormTypeName?selectedFormTypeName?.name +' Feedback':null ?? 'Write Feedback' }}
                    </h4>
                  </div>
                  <VForm ref="feedbackForm">
                    <VSkeletonLoader
                      v-if="questionsComputed.length === 0"
                      height="500"
                      loading-text="Loading..."
                      indeterminate
                      type="card, list-item"
                    />
                 
                    <div
                      v-for="(questionGroup, index) in questionsComputed"
                      :key="index"
                    >
                      <QuestionGroup
                        v-if="!fetchingQuestions"
                        :question-group="questionGroup"
                        type="standard"
                        @update:feedback-type-form-data="updateFeedbackTypeFormData"
                      />
                    </div>
                  </VForm>
                </VContainer>
              </VWindowItem>
              <VWindowItem>
                <!-- Thank you page -->
                <VCardItem>
                  <div class="text-center text-primary text-h5 mb-5">
                    Thank you for your feedback
                  </div>
                  <div class=" text-body-1">
                    <p
                      v-if="!isFeedbackPositive"
                      class="text-center"
                    >
                      Your feedback is valuable to us. We will use this feedback to improve our services and serve you better.
                    </p>
                    <p
                      v-else
                      class="text-center"
                    >
                      Thank you for your positive feedback. We are glad that you are satisfied with our services.
                    </p>
                  </div>
                  <!-- if positive than show google map review button -->
                  <div
                    v-if="isFeedbackPositive && google_map_link"
                    class="d-flex justify-center"
                  >
                    <!-- iframe -->
                    <a :href="google_map_link">
                      <VBtn
                        color="primary"
                        class="mt-5"
                      >
                        Give us 👍 on Google
                        <VIcon icon="tabler-brand-google-maps" />
                      </VBtn>
                    </a>
                  </div>
                </VCardItem>
              </VWindowItem>
              <VCardActions
                class="mt-10 d-flex"
                :class="{
                  'justify-center': (currentStep === 3 && episodeNoValue )|| currentStep === 0,
                  'justify-space-between': !episodeNoValue && currentStep !== 0,
                }"
              >
                <VBtn
                  v-show="currentStep !== 0 && currentStep < 4 && !episodeNoValue "
                  color="primary"
                  @click="previousStep"
                >
                  <VIcon
                    start
                    icon="tabler-arrow-left"
                  />
                  Back
                </VBtn>
                <VBtn
                  v-if="currentStep < 4 "
                  color="primary"
                  :variant="buttonLabel === 'Submit' ? 'tonal' : 'outlined'"
                  :disabled="!checkCurrentStep"
                  :loading="submitBtnLoading"
                  @click="nextStep"
                >
                  {{ buttonLabel }}
                </VBtn>
              </VCardActions>
            </VWindow>
          </VCardText>
        </VCard>
      </div>
    </VContainer>
  </div>
</template>

<style lang="scss">
.feedback-stepper {
  .stepper-icon-step {
    .step-wrapper + svg {
      margin-inline: 3.5rem !important;
    }
  }
}

.feedback-card {
  margin-block: 6rem;
}

@media (max-width: 960px) and (min-width: 600px) {
  .feedback-page {
    .v-container {
      padding-inline: 2rem !important;
    }
  }
}

@media (max-width: 600px){
  .feedback-card {
    margin-block-start: 6rem;
  }
}

.feedback-container{
  min-block-size: 100dvh
}

.google-translate-select-flag {
  .google-translate-select-flag__mr{
    background-image: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIiBpZD0iZmxhZy1pY29ucy1pbiIgdmlld0JveD0iMCAwIDY0MCA0ODAiPgogIDxwYXRoIGZpbGw9IiNmOTMiIGQ9Ik0wIDBoNjQwdjE2MEgweiIvPgogIDxwYXRoIGZpbGw9IiNmZmYiIGQ9Ik0wIDE2MGg2NDB2MTYwSDB6Ii8+CiAgPHBhdGggZmlsbD0iIzEyODgwNyIgZD0iTTAgMzIwaDY0MHYxNjBIMHoiLz4KICA8ZyB0cmFuc2Zvcm09Im1hdHJpeCgzLjIgMCAwIDMuMiAzMjAgMjQwKSI+CiAgICA8Y2lyY2xlIHI9IjIwIiBmaWxsPSIjMDA4Ii8+CiAgICA8Y2lyY2xlIHI9IjE3LjUiIGZpbGw9IiNmZmYiLz4KICAgIDxjaXJjbGUgcj0iMy41IiBmaWxsPSIjMDA4Ii8+CiAgICA8ZyBpZD0iaW4tZCI+CiAgICAgIDxnIGlkPSJpbi1jIj4KICAgICAgICA8ZyBpZD0iaW4tYiI+CiAgICAgICAgICA8ZyBpZD0iaW4tYSIgZmlsbD0iIzAwOCI+CiAgICAgICAgICAgIDxjaXJjbGUgcj0iLjkiIHRyYW5zZm9ybT0icm90YXRlKDcuNSAtOC44IDEzMy41KSIvPgogICAgICAgICAgICA8cGF0aCBkPSJNMCAxNy41LjYgNyAwIDJsLS42IDV6Ii8+CiAgICAgICAgICA8L2c+CiAgICAgICAgICA8dXNlIHhsaW5rOmhyZWY9IiNpbi1hIiB3aWR0aD0iMTAwJSIgaGVpZ2h0PSIxMDAlIiB0cmFuc2Zvcm09InJvdGF0ZSgxNSkiLz4KICAgICAgICA8L2c+CiAgICAgICAgPHVzZSB4bGluazpocmVmPSIjaW4tYiIgd2lkdGg9IjEwMCUiIGhlaWdodD0iMTAwJSIgdHJhbnNmb3JtPSJyb3RhdGUoMzApIi8+CiAgICAgIDwvZz4KICAgICAgPHVzZSB4bGluazpocmVmPSIjaW4tYyIgd2lkdGg9IjEwMCUiIGhlaWdodD0iMTAwJSIgdHJhbnNmb3JtPSJyb3RhdGUoNjApIi8+CiAgICA8L2c+CiAgICA8dXNlIHhsaW5rOmhyZWY9IiNpbi1kIiB3aWR0aD0iMTAwJSIgaGVpZ2h0PSIxMDAlIiB0cmFuc2Zvcm09InJvdGF0ZSgxMjApIi8+CiAgICA8dXNlIHhsaW5rOmhyZWY9IiNpbi1kIiB3aWR0aD0iMTAwJSIgaGVpZ2h0PSIxMDAlIiB0cmFuc2Zvcm09InJvdGF0ZSgtMTIwKSIvPgogIDwvZz4KPC9zdmc+');
    background-size: cover;
    block-size: 100%;
    inline-size: 100%;

  }

  .google-translate-select-flag__bn{
    background-image: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIiBpZD0iZmxhZy1pY29ucy1pbiIgdmlld0JveD0iMCAwIDY0MCA0ODAiPgogIDxwYXRoIGZpbGw9IiNmOTMiIGQ9Ik0wIDBoNjQwdjE2MEgweiIvPgogIDxwYXRoIGZpbGw9IiNmZmYiIGQ9Ik0wIDE2MGg2NDB2MTYwSDB6Ii8+CiAgPHBhdGggZmlsbD0iIzEyODgwNyIgZD0iTTAgMzIwaDY0MHYxNjBIMHoiLz4KICA8ZyB0cmFuc2Zvcm09Im1hdHJpeCgzLjIgMCAwIDMuMiAzMjAgMjQwKSI+CiAgICA8Y2lyY2xlIHI9IjIwIiBmaWxsPSIjMDA4Ii8+CiAgICA8Y2lyY2xlIHI9IjE3LjUiIGZpbGw9IiNmZmYiLz4KICAgIDxjaXJjbGUgcj0iMy41IiBmaWxsPSIjMDA4Ii8+CiAgICA8ZyBpZD0iaW4tZCI+CiAgICAgIDxnIGlkPSJpbi1jIj4KICAgICAgICA8ZyBpZD0iaW4tYiI+CiAgICAgICAgICA8ZyBpZD0iaW4tYSIgZmlsbD0iIzAwOCI+CiAgICAgICAgICAgIDxjaXJjbGUgcj0iLjkiIHRyYW5zZm9ybT0icm90YXRlKDcuNSAtOC44IDEzMy41KSIvPgogICAgICAgICAgICA8cGF0aCBkPSJNMCAxNy41LjYgNyAwIDJsLS42IDV6Ii8+CiAgICAgICAgICA8L2c+CiAgICAgICAgICA8dXNlIHhsaW5rOmhyZWY9IiNpbi1hIiB3aWR0aD0iMTAwJSIgaGVpZ2h0PSIxMDAlIiB0cmFuc2Zvcm09InJvdGF0ZSgxNSkiLz4KICAgICAgICA8L2c+CiAgICAgICAgPHVzZSB4bGluazpocmVmPSIjaW4tYiIgd2lkdGg9IjEwMCUiIGhlaWdodD0iMTAwJSIgdHJhbnNmb3JtPSJyb3RhdGUoMzApIi8+CiAgICAgIDwvZz4KICAgICAgPHVzZSB4bGluazpocmVmPSIjaW4tYyIgd2lkdGg9IjEwMCUiIGhlaWdodD0iMTAwJSIgdHJhbnNmb3JtPSJyb3RhdGUoNjApIi8+CiAgICA8L2c+CiAgICA8dXNlIHhsaW5rOmhyZWY9IiNpbi1kIiB3aWR0aD0iMTAwJSIgaGVpZ2h0PSIxMDAlIiB0cmFuc2Zvcm09InJvdGF0ZSgxMjApIi8+CiAgICA8dXNlIHhsaW5rOmhyZWY9IiNpbi1kIiB3aWR0aD0iMTAwJSIgaGVpZ2h0PSIxMDAlIiB0cmFuc2Zvcm09InJvdGF0ZSgtMTIwKSIvPgogIDwvZz4KPC9zdmc+');
    background-size: cover;
    block-size: 100%;
    inline-size: 100%;

  }

  .google-translate-select-flag__ta{
    background-image: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIiBpZD0iZmxhZy1pY29ucy1pbiIgdmlld0JveD0iMCAwIDY0MCA0ODAiPgogIDxwYXRoIGZpbGw9IiNmOTMiIGQ9Ik0wIDBoNjQwdjE2MEgweiIvPgogIDxwYXRoIGZpbGw9IiNmZmYiIGQ9Ik0wIDE2MGg2NDB2MTYwSDB6Ii8+CiAgPHBhdGggZmlsbD0iIzEyODgwNyIgZD0iTTAgMzIwaDY0MHYxNjBIMHoiLz4KICA8ZyB0cmFuc2Zvcm09Im1hdHJpeCgzLjIgMCAwIDMuMiAzMjAgMjQwKSI+CiAgICA8Y2lyY2xlIHI9IjIwIiBmaWxsPSIjMDA4Ii8+CiAgICA8Y2lyY2xlIHI9IjE3LjUiIGZpbGw9IiNmZmYiLz4KICAgIDxjaXJjbGUgcj0iMy41IiBmaWxsPSIjMDA4Ii8+CiAgICA8ZyBpZD0iaW4tZCI+CiAgICAgIDxnIGlkPSJpbi1jIj4KICAgICAgICA8ZyBpZD0iaW4tYiI+CiAgICAgICAgICA8ZyBpZD0iaW4tYSIgZmlsbD0iIzAwOCI+CiAgICAgICAgICAgIDxjaXJjbGUgcj0iLjkiIHRyYW5zZm9ybT0icm90YXRlKDcuNSAtOC44IDEzMy41KSIvPgogICAgICAgICAgICA8cGF0aCBkPSJNMCAxNy41LjYgNyAwIDJsLS42IDV6Ii8+CiAgICAgICAgICA8L2c+CiAgICAgICAgICA8dXNlIHhsaW5rOmhyZWY9IiNpbi1hIiB3aWR0aD0iMTAwJSIgaGVpZ2h0PSIxMDAlIiB0cmFuc2Zvcm09InJvdGF0ZSgxNSkiLz4KICAgICAgICA8L2c+CiAgICAgICAgPHVzZSB4bGluazpocmVmPSIjaW4tYiIgd2lkdGg9IjEwMCUiIGhlaWdodD0iMTAwJSIgdHJhbnNmb3JtPSJyb3RhdGUoMzApIi8+CiAgICAgIDwvZz4KICAgICAgPHVzZSB4bGluazpocmVmPSIjaW4tYyIgd2lkdGg9IjEwMCUiIGhlaWdodD0iMTAwJSIgdHJhbnNmb3JtPSJyb3RhdGUoNjApIi8+CiAgICA8L2c+CiAgICA8dXNlIHhsaW5rOmhyZWY9IiNpbi1kIiB3aWR0aD0iMTAwJSIgaGVpZ2h0PSIxMDAlIiB0cmFuc2Zvcm09InJvdGF0ZSgxMjApIi8+CiAgICA8dXNlIHhsaW5rOmhyZWY9IiNpbi1kIiB3aWR0aD0iMTAwJSIgaGVpZ2h0PSIxMDAlIiB0cmFuc2Zvcm09InJvdGF0ZSgtMTIwKSIvPgogIDwvZz4KPC9zdmc+');
    background-size: cover;
    block-size: 100%;
    inline-size: 100%;

  }

  .google-translate-select-flag__te{
    background-image: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIiBpZD0iZmxhZy1pY29ucy1pbiIgdmlld0JveD0iMCAwIDY0MCA0ODAiPgogIDxwYXRoIGZpbGw9IiNmOTMiIGQ9Ik0wIDBoNjQwdjE2MEgweiIvPgogIDxwYXRoIGZpbGw9IiNmZmYiIGQ9Ik0wIDE2MGg2NDB2MTYwSDB6Ii8+CiAgPHBhdGggZmlsbD0iIzEyODgwNyIgZD0iTTAgMzIwaDY0MHYxNjBIMHoiLz4KICA8ZyB0cmFuc2Zvcm09Im1hdHJpeCgzLjIgMCAwIDMuMiAzMjAgMjQwKSI+CiAgICA8Y2lyY2xlIHI9IjIwIiBmaWxsPSIjMDA4Ii8+CiAgICA8Y2lyY2xlIHI9IjE3LjUiIGZpbGw9IiNmZmYiLz4KICAgIDxjaXJjbGUgcj0iMy41IiBmaWxsPSIjMDA4Ii8+CiAgICA8ZyBpZD0iaW4tZCI+CiAgICAgIDxnIGlkPSJpbi1jIj4KICAgICAgICA8ZyBpZD0iaW4tYiI+CiAgICAgICAgICA8ZyBpZD0iaW4tYSIgZmlsbD0iIzAwOCI+CiAgICAgICAgICAgIDxjaXJjbGUgcj0iLjkiIHRyYW5zZm9ybT0icm90YXRlKDcuNSAtOC44IDEzMy41KSIvPgogICAgICAgICAgICA8cGF0aCBkPSJNMCAxNy41LjYgNyAwIDJsLS42IDV6Ii8+CiAgICAgICAgICA8L2c+CiAgICAgICAgICA8dXNlIHhsaW5rOmhyZWY9IiNpbi1hIiB3aWR0aD0iMTAwJSIgaGVpZ2h0PSIxMDAlIiB0cmFuc2Zvcm09InJvdGF0ZSgxNSkiLz4KICAgICAgICA8L2c+CiAgICAgICAgPHVzZSB4bGluazpocmVmPSIjaW4tYiIgd2lkdGg9IjEwMCUiIGhlaWdodD0iMTAwJSIgdHJhbnNmb3JtPSJyb3RhdGUoMzApIi8+CiAgICAgIDwvZz4KICAgICAgPHVzZSB4bGluazpocmVmPSIjaW4tYyIgd2lkdGg9IjEwMCUiIGhlaWdodD0iMTAwJSIgdHJhbnNmb3JtPSJyb3RhdGUoNjApIi8+CiAgICA8L2c+CiAgICA8dXNlIHhsaW5rOmhyZWY9IiNpbi1kIiB3aWR0aD0iMTAwJSIgaGVpZ2h0PSIxMDAlIiB0cmFuc2Zvcm09InJvdGF0ZSgxMjApIi8+CiAgICA8dXNlIHhsaW5rOmhyZWY9IiNpbi1kIiB3aWR0aD0iMTAwJSIgaGVpZ2h0PSIxMDAlIiB0cmFuc2Zvcm09InJvdGF0ZSgtMTIwKSIvPgogIDwvZz4KPC9zdmc+');
    background-size: cover;
    block-size: 100%;
    inline-size: 100%;

  }

  .google-translate-select-flag__kn{
    background-image: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIiBpZD0iZmxhZy1pY29ucy1pbiIgdmlld0JveD0iMCAwIDY0MCA0ODAiPgogIDxwYXRoIGZpbGw9IiNmOTMiIGQ9Ik0wIDBoNjQwdjE2MEgweiIvPgogIDxwYXRoIGZpbGw9IiNmZmYiIGQ9Ik0wIDE2MGg2NDB2MTYwSDB6Ii8+CiAgPHBhdGggZmlsbD0iIzEyODgwNyIgZD0iTTAgMzIwaDY0MHYxNjBIMHoiLz4KICA8ZyB0cmFuc2Zvcm09Im1hdHJpeCgzLjIgMCAwIDMuMiAzMjAgMjQwKSI+CiAgICA8Y2lyY2xlIHI9IjIwIiBmaWxsPSIjMDA4Ii8+CiAgICA8Y2lyY2xlIHI9IjE3LjUiIGZpbGw9IiNmZmYiLz4KICAgIDxjaXJjbGUgcj0iMy41IiBmaWxsPSIjMDA4Ii8+CiAgICA8ZyBpZD0iaW4tZCI+CiAgICAgIDxnIGlkPSJpbi1jIj4KICAgICAgICA8ZyBpZD0iaW4tYiI+CiAgICAgICAgICA8ZyBpZD0iaW4tYSIgZmlsbD0iIzAwOCI+CiAgICAgICAgICAgIDxjaXJjbGUgcj0iLjkiIHRyYW5zZm9ybT0icm90YXRlKDcuNSAtOC44IDEzMy41KSIvPgogICAgICAgICAgICA8cGF0aCBkPSJNMCAxNy41LjYgNyAwIDJsLS42IDV6Ii8+CiAgICAgICAgICA8L2c+CiAgICAgICAgICA8dXNlIHhsaW5rOmhyZWY9IiNpbi1hIiB3aWR0aD0iMTAwJSIgaGVpZ2h0PSIxMDAlIiB0cmFuc2Zvcm09InJvdGF0ZSgxNSkiLz4KICAgICAgICA8L2c+CiAgICAgICAgPHVzZSB4bGluazpocmVmPSIjaW4tYiIgd2lkdGg9IjEwMCUiIGhlaWdodD0iMTAwJSIgdHJhbnNmb3JtPSJyb3RhdGUoMzApIi8+CiAgICAgIDwvZz4KICAgICAgPHVzZSB4bGluazpocmVmPSIjaW4tYyIgd2lkdGg9IjEwMCUiIGhlaWdodD0iMTAwJSIgdHJhbnNmb3JtPSJyb3RhdGUoNjApIi8+CiAgICA8L2c+CiAgICA8dXNlIHhsaW5rOmhyZWY9IiNpbi1kIiB3aWR0aD0iMTAwJSIgaGVpZ2h0PSIxMDAlIiB0cmFuc2Zvcm09InJvdGF0ZSgxMjApIi8+CiAgICA8dXNlIHhsaW5rOmhyZWY9IiNpbi1kIiB3aWR0aD0iMTAwJSIgaGVpZ2h0PSIxMDAlIiB0cmFuc2Zvcm09InJvdGF0ZSgtMTIwKSIvPgogIDwvZz4KPC9zdmc+');
    background-size: cover;
    block-size: 100%;
    inline-size: 100%;

  }

  .google-translate-select-flag__ml{
    background-image: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIiBpZD0iZmxhZy1pY29ucy1pbiIgdmlld0JveD0iMCAwIDY0MCA0ODAiPgogIDxwYXRoIGZpbGw9IiNmOTMiIGQ9Ik0wIDBoNjQwdjE2MEgweiIvPgogIDxwYXRoIGZpbGw9IiNmZmYiIGQ9Ik0wIDE2MGg2NDB2MTYwSDB6Ii8+CiAgPHBhdGggZmlsbD0iIzEyODgwNyIgZD0iTTAgMzIwaDY0MHYxNjBIMHoiLz4KICA8ZyB0cmFuc2Zvcm09Im1hdHJpeCgzLjIgMCAwIDMuMiAzMjAgMjQwKSI+CiAgICA8Y2lyY2xlIHI9IjIwIiBmaWxsPSIjMDA4Ii8+CiAgICA8Y2lyY2xlIHI9IjE3LjUiIGZpbGw9IiNmZmYiLz4KICAgIDxjaXJjbGUgcj0iMy41IiBmaWxsPSIjMDA4Ii8+CiAgICA8ZyBpZD0iaW4tZCI+CiAgICAgIDxnIGlkPSJpbi1jIj4KICAgICAgICA8ZyBpZD0iaW4tYiI+CiAgICAgICAgICA8ZyBpZD0iaW4tYSIgZmlsbD0iIzAwOCI+CiAgICAgICAgICAgIDxjaXJjbGUgcj0iLjkiIHRyYW5zZm9ybT0icm90YXRlKDcuNSAtOC44IDEzMy41KSIvPgogICAgICAgICAgICA8cGF0aCBkPSJNMCAxNy41LjYgNyAwIDJsLS42IDV6Ii8+CiAgICAgICAgICA8L2c+CiAgICAgICAgICA8dXNlIHhsaW5rOmhyZWY9IiNpbi1hIiB3aWR0aD0iMTAwJSIgaGVpZ2h0PSIxMDAlIiB0cmFuc2Zvcm09InJvdGF0ZSgxNSkiLz4KICAgICAgICA8L2c+CiAgICAgICAgPHVzZSB4bGluazpocmVmPSIjaW4tYiIgd2lkdGg9IjEwMCUiIGhlaWdodD0iMTAwJSIgdHJhbnNmb3JtPSJyb3RhdGUoMzApIi8+CiAgICAgIDwvZz4KICAgICAgPHVzZSB4bGluazpocmVmPSIjaW4tYyIgd2lkdGg9IjEwMCUiIGhlaWdodD0iMTAwJSIgdHJhbnNmb3JtPSJyb3RhdGUoNjApIi8+CiAgICA8L2c+CiAgICA8dXNlIHhsaW5rOmhyZWY9IiNpbi1kIiB3aWR0aD0iMTAwJSIgaGVpZ2h0PSIxMDAlIiB0cmFuc2Zvcm09InJvdGF0ZSgxMjApIi8+CiAgICA8dXNlIHhsaW5rOmhyZWY9IiNpbi1kIiB3aWR0aD0iMTAwJSIgaGVpZ2h0PSIxMDAlIiB0cmFuc2Zvcm09InJvdGF0ZSgtMTIwKSIvPgogIDwvZz4KPC9zdmc+');
    background-size: cover;
    block-size: 100%;
    inline-size: 100%;

  }

  .google-translate-select-flag__pa{
    background-image: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIiBpZD0iZmxhZy1pY29ucy1pbiIgdmlld0JveD0iMCAwIDY0MCA0ODAiPgogIDxwYXRoIGZpbGw9IiNmOTMiIGQ9Ik0wIDBoNjQwdjE2MEgweiIvPgogIDxwYXRoIGZpbGw9IiNmZmYiIGQ9Ik0wIDE2MGg2NDB2MTYwSDB6Ii8+CiAgPHBhdGggZmlsbD0iIzEyODgwNyIgZD0iTTAgMzIwaDY0MHYxNjBIMHoiLz4KICA8ZyB0cmFuc2Zvcm09Im1hdHJpeCgzLjIgMCAwIDMuMiAzMjAgMjQwKSI+CiAgICA8Y2lyY2xlIHI9IjIwIiBmaWxsPSIjMDA4Ii8+CiAgICA8Y2lyY2xlIHI9IjE3LjUiIGZpbGw9IiNmZmYiLz4KICAgIDxjaXJjbGUgcj0iMy41IiBmaWxsPSIjMDA4Ii8+CiAgICA8ZyBpZD0iaW4tZCI+CiAgICAgIDxnIGlkPSJpbi1jIj4KICAgICAgICA8ZyBpZD0iaW4tYiI+CiAgICAgICAgICA8ZyBpZD0iaW4tYSIgZmlsbD0iIzAwOCI+CiAgICAgICAgICAgIDxjaXJjbGUgcj0iLjkiIHRyYW5zZm9ybT0icm90YXRlKDcuNSAtOC44IDEzMy41KSIvPgogICAgICAgICAgICA8cGF0aCBkPSJNMCAxNy41LjYgNyAwIDJsLS42IDV6Ii8+CiAgICAgICAgICA8L2c+CiAgICAgICAgICA8dXNlIHhsaW5rOmhyZWY9IiNpbi1hIiB3aWR0aD0iMTAwJSIgaGVpZ2h0PSIxMDAlIiB0cmFuc2Zvcm09InJvdGF0ZSgxNSkiLz4KICAgICAgICA8L2c+CiAgICAgICAgPHVzZSB4bGluazpocmVmPSIjaW4tYiIgd2lkdGg9IjEwMCUiIGhlaWdodD0iMTAwJSIgdHJhbnNmb3JtPSJyb3RhdGUoMzApIi8+CiAgICAgIDwvZz4KICAgICAgPHVzZSB4bGluazpocmVmPSIjaW4tYyIgd2lkdGg9IjEwMCUiIGhlaWdodD0iMTAwJSIgdHJhbnNmb3JtPSJyb3RhdGUoNjApIi8+CiAgICA8L2c+CiAgICA8dXNlIHhsaW5rOmhyZWY9IiNpbi1kIiB3aWR0aD0iMTAwJSIgaGVpZ2h0PSIxMDAlIiB0cmFuc2Zvcm09InJvdGF0ZSgxMjApIi8+CiAgICA8dXNlIHhsaW5rOmhyZWY9IiNpbi1kIiB3aWR0aD0iMTAwJSIgaGVpZ2h0PSIxMDAlIiB0cmFuc2Zvcm09InJvdGF0ZSgtMTIwKSIvPgogIDwvZz4KPC9zdmc+');
    background-size: cover;
    block-size: 100%;
    inline-size: 100%;

  }
}
</style>
